.breadcrumb {
    .breadcrumb-item {
        a {
            text-decoration: underline;
            color: $breadcrumb-color;
            span {
                color: $breadcrumb-color;
            }
        }
    }
}