#identity,
#addresses,
#history,
#order-slip,
#discount,
#order-follow,
#module-psgdpr-gdpr,
#module-ps_emailalerts-account,
#authentication {

    .page-header {
        background-color: transparent;
        border-bottom: none;
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        color: $h1-color;
    }


    .page-wrapper--identity,
    .page-wrapper--addresses,
    .page-wrapper--history,
    .page-wrapper--order-slip,
    .page-wrapper--discount,
    .page-wrapper--order-follow,
    .page-wrapper--module-psgdpr-gdpr,
    .page-wrapper--module-ps_emailalerts-account,
    .page-wrapper--authentication {
        border: none;


        .btn.btn-light {
            border-color: $h1-color;
            background-color: $h1-color;
            color: white;
        }

        .register-form {
            > p {
                text-align: center;
            }
        }
    }

    .btn.btn-primary {
        width: 206px;
        height: 45px;
        border-radius: 23px;
        background-color: #001746;
        color: white;
        margin: 0 auto;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .page-footer--identity,
    .page-footer--addresses,
    .page-footer--history,
    .page-footer--order-slip,
    .page-footer--discount,
    .page-footer--order-follow,
    .page-footer--module-psgdpr-gdpr,
    .page-footer--module-ps_emailalerts-account,
    .page-footer--authentication {
        background-color: transparent;
        text-align: center;
        border: none;


        a {
            border: 1px solid $primary;
            border-radius: 22.5px;
            padding: 10px 20px;
            margin: 0 10px;


            &:hover {
                background-color: $primary;
                color: white;
            }
        }


    }


    .page-content--addresses {
        .row {
            padding: 0;

            .address {
                border: 1px solid $h1-color;
            }
        }
    }
}