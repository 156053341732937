.l-footer {
  color: $text-muted;


  .cms-page-link,
  .account-list a{
    color: $text-muted;
    &:hover{
      color: theme-color('primary');

    }
  }
}
.footer-container{
  margin-top: $footer-container-margin-top;
  background-color: #001746;
  .container {
    max-width: calc(100% - 14%);
    padding-left: 7%;
    padding-right: 7%;

    > .row {
      > div.elementor{
        width: 100%;

        .elementor-container {
          max-width: 100%;

          .elementor-widget-wrap {

            > div {
              width: 25%;
            }

            > div:nth-child(1),
            > div:nth-child(2) {
              width: 15%;
            }

            div:nth-child(4) {
              width: 17%;
            }

            div:nth-child(3) {
              width: 40%;
            }
            
            #categories-footer {
                > div {
                  background: transparent;

                  .block-categories {
                    background-color: transparent;

                    .h5 {
                      color: $primary;
                      text-transform: capitalize !important;
                      font-weight: bold;
                      font-size: 14px;
                    }

                    ul li {

                      border-bottom: none;
                      a {
                        font-size: 14px;
                        color: white;

                        &:hover {
                          color: $primary;
                        }
                      }
                    }
                  }
                }
            }

            #services-footer {
              padding-top: 20px;
              border-right: 1px solid white;
              .services {
                .services-title {
                  color: $primary;
                  text-transform: capitalize;
                  font-weight: bold;
                  font-size: 14px;
                }

                ul {
                  li {
                    margin-top: 7px;
                    a {
                      color: white;
                      font-size: 14px;

                      &:hover {
                        color: $primary;
                      }
                    }
                  }
                }
              }
            }

            #magasin-footer {
              padding-left: 20px;
              padding-top: 20px;
              border-right: 1px solid white;

              .magasin {
                .magasin-title {
                  color: $primary;
                  text-transform: capitalize;
                  font-weight: bold;
                  font-size: 14px;
                }

                p {
                  color: white;
                  font-size: 14px;
                }
              }
            }

            #mentions-footer {
              padding-left: 20px;
              padding-top: 20px;

              .mentions {
                ul {
                  li {
                    a {
                      color: gray;

                      &:hover {
                        color: $primary;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.footer__title{
  color:$black;
  &:visited,&:hover{
    color: $black;
  }
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  margin-bottom: $spacer/2;
  &--mobile{
    display: none;
    margin-bottom: 0;
    padding: $spacer/2 0;
    border-bottom: 1px solid $border-color;
    

    &:after{
      content:"\e313";
      font-family: 'Material Icons';
      font-feature-settings: 'liga' 1;
      position: absolute;
      right: $grid-gutter-width/2;
      transition: .3s transform ease-in-out;
      font-size: $font-size-base *1.5;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform:         rotate(180deg);
    }
  }
}

@include media-breakpoint-mobile {
  .footer__title{
    &--desktop{

      display: none;
    }
    &--mobile{
      display: block;
    }
  }
}
.footer__copyright{
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav{
  align-items: center;
}
.header-nav__nav2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top{
  display: flex;
  justify-content: space-between;
  position: static;
}


.blockreassurance {
  > .row {
    > div {
      position: relative;

        &::after {
          content : '';
          border-right: 1px solid #001746;
          position: absolute;
          right: 0;
          top: 20px;
          width: 1px;
          height: 65%;
        }


        &:last-child {
          &::after {
            display: none;
          }
        }
    }
  }
}

