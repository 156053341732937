#contact {

    #wrapper {
        background-color: #f1f1ff;
    }

    #left-column {
        display: none;
    }

    #content-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
    }

    #main {
        background-color: transparent;
        border: none;

        .elementor-container {
            max-width: 100%;
        
        }

        #form-contact {
            form {
                width: 100%;
                max-width: 100%;

                #submitForm {
                    background-color: $primary;
                    color: white;
                    border: 1px solid $primary;
                    border-radius: 22px;

                    &:hover {
                        background-color: transparent;
                        color: $primary;
                    }
                }
            }
        }
    }
}