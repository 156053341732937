#category,
#new-products,
#prices-drop,
#best-sales,
#search {
    .l-wrapper {
        background: #f1f1ff;

        .card-block {
            background: transparent;
            border: none;
        }
    
        #_desktop_category_header {
            display: flex;
            justify-content: center;

            h1 {
                font-size: 30px;
                color: $h1-color;
                padding-bottom: 30px;
                font-weight: bold;
            }
        }

        .d-flex {
            flex-wrap: wrap;
            justify-content: center;

            #category-description {
                width: 100%;

                p,
                span {
                    background: none !important;
                    text-align: center !important;
                    font-size: 18px !important;
                    width: 75% !important;
                    margin: 0 auto !important;
                    line-height: 26px !important;
                    color: $h1-color;
                }
            }
        }
    }


    #search_filters {
        .h6 {
            text-transform: capitalize !important;
            font-size: 22px !important;
            color: $h1-color !important;
            border-bottom: 1px solid $h1-color;
            padding-bottom: 30px;
            font-weight: bold;
        }

        .facet__title {
            font-size: 18px;
            font-weight: 500;
            color: $h1-color;
        }

        .custom-control {

            span {
                color: $h1-color;
            }
            .custom-control-label:before {
                border: 1px solid #0002C0;
                background-color: transparent;
    
            }
        }

        .custom-control--active {
            span {
                font-weight: bold;
            }
            .custom-control-label:before {
                border: 1px solid #0002C0;
                background-color: #0002C0;
    
                
            }
        }

        .facet__block {
            border-bottom: 1px solid $h1-color;
        }

        .ui-slider {
            background: #979797;
            height: 4px;

            &::before {
                content: '';
                width: 10px;
                height: 10px;
                display: block;
                position: absolute;
                left: -1px;
                top: -4.25px;
                background: #979797;
                border-radius: 50%;
            }

            &::after {
                content: '';
                width: 10px;
                height: 10px;
                display: block;
                position: absolute;
                right: -8px;
                top: -4.25px;
                background: #979797;
                border-radius: 50%;
            }

            .ui-slider-handle {
                border: none;
                height: 10px;
                width: 10px;
                top: -4.25px;
                background: #0002C0;
            }

            .ui-slider-range {
                background: #0002C0;
                height: 4px;
                top: -1px;
            }
        }

        .icon-collapse {
            color: $h1-color;
        }

        .btn--clearfilter {
            border: 1px solid $h1-color;
            color: $h1-color;

            i {
                color: $h1-color;
            }

            &:hover {
                background: $h1-color;
                color: white;

                i {
                    color: white;
                }
            }
        }
    }

    .products {
        padding: 0 !important;
        .product-miniature {
            flex: 0 0 100%;
            max-width: 100%;

            .stars-container {
                display: none;
            }

            .card {
                flex-direction: row;
                min-height: 182px;

                &:hover {
                    border: 1px solid $primary;
                }

                .card-img, .card-img-bottom, .card-img-top {
                    width: 20%;
                }


                .card-body {
                    flex: 0 0 80%;
                    padding: 0;

                    > .product-description  {
                        > .product-title,
                        > .product-price-and-shipping {
                            display: none;
                        }
                    }
                    .row-product {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        .left {
                            width: calc(80% - 40px);
                            min-width: calc(80% - 40px);
                            padding: 20px;
                        
                            .product-title {
                                text-align: left;
                                color: $h1-color;
                                text-transform: uppercase;
                            }

                            .product_shortdesc {
                                max-width: 80%;

                                * {
                                    font-size: 14px !important;
                                    font-weight: normal !important;
                                }
                            }

                            .product_quantities {
                                position: relative;
                                font-size: 12px;
                                margin-left: 20px;
                                color: $h1-color;
                                &::before {
                                    content: '';
                                    width: 11px;
                                    height: 11px;
                                    display: block;
                                    position: absolute;
                                    left: -18px;
                                    top: 4px;
                                    background: url('../img/oeil.png') no-repeat center;
                                    background-size: contain;
                                }
                            }
                        }

                        
                        .right {
                            width: calc(30% - 40px);
                            width: calc(30% - 40px);
                            padding: 20px;
                            border-left: 1px solid #F1F1FF;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            .product-price-and-shipping {
                                display: flex;
                                justify-content: center;
                                flex-wrap: wrap;
                                flex-direction: column-reverse;

                                > span {
                                    width: 100%;
                                }

                            }
                            .bootstrap-touchspin {
                                display: none !important;
                            }
                        
                            form {
                                display: flex;
                                justify-content: center;
                            }

                            .add-to-cart {
                                background-color: $primary;
                                border-radius: 22.5px;
                                color: $h1-color;
                                margin-top: 20px;

                                &:hover {
                                    color: white;
                                    background-color: $h1-color;
                                }
                            }
                        }
                    }
                    .product__card-desc {
                        flex-direction: row;
                    }
                }
            }
        }
    }

    #product-list-header {
        display: none;
    }

    #js-product-list-top,
    #js-product-list {
        padding: 0;

        .row {
            padding: 0;
        }

        label,
        .product__quantity {
            font-size: 12px; 
            color: $h1-color;
        }

        #select-sort-order {
            border-top-left-radius: 22.5px;
            border-bottom-left-radius: 22.5px;
        }

        #search_filter_toggler {
            background-color: $primary;
            border-top-right-radius: 22.5px;
            border-bottom-right-radius: 22.5px;
            border:none;

            &:hover {
                background-color: $h1-color;
            }
        }
    }

    // Pagination 
    #js-product-list {
        .u-a-i-c {
            > div {
                display: none;
            }

            nav {
                flex: 0 0 100%;

                ul {
                    li {
                        border: none;
                        a {
                            color: $h1-color;
                            background-color: transparent;
                            border: none;
                            font-size: 15px;
                        }

                        &.active {
                            a {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
    }

    .btn-secondary {
        background-color: $h1-color;
        font-size: 14px;
    }

    #subcategories {
        width: 100%;
        padding-bottom: 50px;

        > ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;

            > li {
                width: 259px;
                flex: inherit;
                background-color: white;
                display: flex;
                justify-content: center;
                max-width: initial;
                margin: 10px 10px;
                box-shadow: 0 0 2px rgba(0, 23, 70, 0.2);
                min-height: 100px;
                align-items: center;


                .h3 {
                    text-align: center;
                    font-size: 22px;
                    font-weight: 600;
                    color: #001746;
                }
            }
        }
    }
}