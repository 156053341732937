
#product {
    #wrapper > div > div.row {
        padding: 10px 0px;
        width: 100%;
        margin: 30px auto;
    }

    .l-wrapper {
        background: #f1f1ff;

        #main > .row {
            padding: 10px 0;

        }

        .head_product {
            padding: 10px;
            background-color: white;


            > .col-lg-7 {
                padding-top: 35px;

                h1 {
                    font-size: 30px;
                    color: $h1-color;
                    font-weight: bold;
                }

                .product-information {
                    font-size: 18px;

                    * {
                        font-size: 18px !important;
                        font-weight: normal !important;
                        color: $h1-color !important;
                    }
                }

                .product-actions {
                    margin-top: 50px;
                }

                .product-variants {
                    text-align: center;
                }

                #add-to-cart-or-refresh {
                    display: flex;
                    flex-wrap: wrap;

                    > div {
                        flex: 0 0 calc(33% - 20px);
                        border-right: 1px solid $h1-color;
                        padding: 0 10px;
                        

                        &:last-child {
                            border-right: none;
                        }
                    }

                    .product-prices {
                        text-align: center;
                        line-height: 1;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: space-evenly;

                        .current-price {
                            span {
                                color: $primary !important;
                                font-size: 40px !important;
                                font-weight: bold !important;
                            }

                            .discount-percentage {
                                font-size: 12px !important;
                                color: white !important;

                            }
                        }

                        .tax-shipping-delivery-label {
                            display: none;
                        }

                        .product_quantities {
                            position: relative;
                            font-size: 12px !important;
                            margin-left: 20px;
                            color: $h1-color !important;
                            text-align: initial;
                            margin: 0 auto;
                            display: block;
                            width: fit-content;

                            &::before {
                                content: '';
                                width: 11px;
                                height: 11px;
                                display: block;
                                position: absolute;
                                left: -18px;
                                top: 4px;
                                background: url('../img/oeil.png') no-repeat center;
                                background-size: contain;
                            }

                        }
                    }


                    .product-add-to-cart {
                        text-align: center;

                        .quantity__label {
                            font-size: 14px !important;
                            color: $h1-color;
                            font-weight: bold !important;
                        }

                        .bootstrap-touchspin {
                            box-shadow: none;
                            border: none;
                            justify-content: center;

                            #quantity_wanted {
                                background-color: #F1F1FF;
                                border-radius: 22.5px;
                            }

                            button {
                                font-size: 25px !important;
                                padding: 0 10px !important;
                            }
                        }
                    }

                    .product--button-add {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        button {
                            border-radius: 22.5px;
                            font-size: 12px !important;
                            width: fit-content;
                            padding: 10px 25px;
                            i {
                                // margin-top: 4px;
                                // margin-left: 10px;
                            }

                            &:hover {
                                background-color: $h1-color;
                                color: white !important;
                                border-color: $h1-color !important;

                                i {
                                    color: white !important;
                                }
                            }
                        }
                    }
                    
                }

                .reassurance-home {
                    margin-top: 30px;
                    background-color: $h1-color;
                
                    p {
                        font-weight: normal !important;
                    }
                }
            }
        }
    }


    .tabs {
        background: transparent;
        border: none;

        .nav {
            li {
                a {
                    text-transform: uppercase;
                    color: $h1-color;
                    border: none;
                    font-size: 14px;
                }
            }
        }

        .tab-content {
            background: white;
            padding: 10px;

            .product-description {
                color: $h1-color;
            }
        }
    }

    .product-accessories {

        .products {
            // width: 80%;
            // margin: 0 10%;
            margin-left: 15px;
            
            .product-miniature {
                flex: 0 0 100%;
                max-width: 100%;
            
                .card-body {
                    min-height: 120px;
                }

                .stars-container {
                    display: none;
                }
            }



            .owl-item{
                visibility: hidden;
            }

            .owl-item.active {
                visibility: visible;
            }
        }
        .row {
            padding: 10px 0;
        }

        .owl-nav {
            position: absolute;
            top: 30%;
            width: 85vw;
            left: -30px;

            .owl-prev {
                span {
                    font-size: 60px;
                    color: $h1-color;
                }
            }

            .owl-next {
                position: absolute;
                right: 0;

                
                span {
                    font-size: 60px;
                    color: $h1-color;
                }
            }
        }
    }

    .products-section-title {
        background: white;
        color: #0002C0;
        text-transform: uppercase;
        width: fit-content;
        margin: 0 auto;
        display: block;
        padding: 10px 20px;
    }

    #footer {
        .row {
            padding: 0;
        }
    }
}