#cms {
    
    #wrapper {
        background-color: #f1f1ff;
    
        > .row {
            padding-bottom: 0;
        }
    }

    // .row {
    //     padding: 10px 0;
    // }
    .page-header--cms {
        display: none;
    }

    #main {
        border: none;
        background-color: #f1f1ff;

        #content {
            padding: 0;
        }

        #maps-mag {
            > div {
                max-width: inherit;

                > .elementor-row {
                    div:nth-child(1) {
                        > div {
                            padding: 0;
                            
                        }

                        #maps-google {
                            height: 100%;
                            > div {
                                height: 100%;
                            }
                            iframe {
                                height: 100%;
                            }
                        }
                    }

                    div:nth-child(2) {
                        background-color: white;

                        p {
                            color: #001746 !important;
                            strong {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }

        #magasin {
            padding-top: 20px;
            width: 100vw;
            margin-left: -13%;
    
            .blocs-magasin {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
    
                a {
                    width: 28%;
                    height: 125px;
                    background-color: #001746;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 20px;
    
                    img {
                        height: 40px;
                    }
    
                    p {
                        text-align: center;
                        width: 100%;
                        color: white;
                    }
                }
            }
        }

        #col-services {
            .elementor-column-gap-default {
                max-width: inherit;
            }

            .elementor-widget-image {
                margin: 0;
            }
        }

        #grille-tarif {
            width: 100vw;
            margin-left: -13%;
            margin-top: 50px;
        }

        .grille-cms {
            width: 100%;
            thead {
                width: 100%;
                border: 2px solid #001746;
                tr {
                    width: 100%;
                    td {
                        border-right: 2px solid #001746;
                        width: 25%;
                        text-align: center;
                        font-size: 16px;
                        color: #001746;
                        font-weight: 700;
                    }

                    td:last-child {
                        border-right: none;
                    }
                }
            }
            tbody {
                width: 100%;
                border: 2px solid #001746;
                tr {
                    width: 100%;
                    td {
                        border-right: 2px solid #001746;
                        border-bottom: 1px solid #001746;
                        width: 25%;
                        text-align: center;
                        font-size: 16px;
                        // color: #001746;
                        font-weight: 700;
                        min-height: 20px;
                    }

                    td:last-child {
                        border-right: none;
                    }
                }
            }
        }
    }

}