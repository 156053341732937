#checkout {

    .header-nav {
        display: flex;
        justify-content: center;
    }

    #checkout-personal-information-step,
    #content-checkout-personal-information-step,
    #checkout-addresses-step,
    #checkout-delivery-step,
    #checkout-payment-step
     {
        // border-top: 1px solid $h1-color;
        border-left: 1px solid $h1-color;
        border-right: 1px solid $h1-color;
        
        .card-header {
            border-bottom: none;
            background-color: $primary;
            h1 {
                color: white;

                .step-number {

                }
            }
        }

        .step-edit {
            color: white !important;
        }

        #conditions-to-approve {
            padding: 0;
            background: transparent;
            border: none;
        }

        .continue {
            width: 206px;
            height: 45px;
            border-radius: 23px;
            background-color: #001746;
            color: white;
            margin: 0 auto;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .delivery-option {
            background-color: transparent;
            border: 1px solid $primary !important;
            
            .row {
                padding: 0;

                .col-9 {
                    display: flex;
                    align-items: center;
                }
            }

            &:hover {
                border: 1px solid $h1-color;
            }
        }
    }
    
    #checkout-personal-information-step {
        .nav {
            border-bottom: none;
            
            .nav-item {
                color: white;
                background-color: $primary;
                border: 1px solid $primary;
                margin-bottom: 0;

                a {
                    border: none;
                    color: white;
                }
            }
            
            .nav-item {
                color: white;
                background-color: $primary;

                a.active {
                    color: $primary;
                }
                
            }

        }

        #customer-form {
            width: 100%;

            label {
                color: $h1-color;
            }

            .btn.btn-light {
                border-color: $h1-color;
                background-color: $h1-color;
                color: white;
            }
        }

        
    }


    #content-checkout-payment-step.show {
        border-bottom: 1px solid $h1-color;
    }


    #delivery-addresses {
        .address-item,
        > div {
            flex : 0 0 100%;
            max-width: 100%;
        }

    }

    .js-cart {
        border: 1px solid #001746;

        .cart-summary-line {
            .label {
                font-weight: bold;
            }
        }


        .cart-total {
            background-color: transparent;
            flex-wrap: wrap;

            .label, .value {
                width: 100%;
                text-align: center;
            }

            .label {
                color: #001746;
                font-size: 14px;
                font-weight: 700;
            }

            .value {
                color: $primary;
                font-size: 40px;
                font-weight: 700;
            }
        }

        .block-promo {
            .promo-code__content {
                background-color: transparent;
            }
        }

        .card-footer {
            background-color: transparent;
            border: none;

            .checkout {
                .btn-primary {
                    width: 206px;
                    height: 45px;
                    border-radius: 23px;
                    background-color: #001746;
                    color: white;
                    margin: 0 auto;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 700;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .separator {
            display: none;
        }
    }
}

#order-confirmation {
    #content {
        border:none;
    }
}