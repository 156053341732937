#my-account {

    h1 {
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        color: $h1-color;
    }    


    .l-wrapper {
        background: #f1f1ff;
    
    

        .account-links {
            padding: 50px 0;


            > a {
                max-width: 25%;
                flex: 0 0 25%;
                height: 125px;

                .link-item {
                    font-size: 16px;
                    font-weight: 600;
                    color: $h1-color;
                    text-transform: initial;

                    
                }
            }

            #identity-link,
            #addresses-link,
            #history-link,
            #order-slips-link,
            #discounts-link,
            #returns-link,
            #emailsalerts,
            #psgdpr-link {
                .link-item {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 55px;

                    &::before {
                        content: '';
                        display: block;
                        width: 35px;
                        height: 35px;   
                        position: absolute;
                        left: 45%;
                        top: 20px;
                        background-repeat: no-repeat;
                        background-position: center;
                    }

                    i {
                        display: none;
                    }
                }
            }

            #identity-link {
                .link-item {
                    &::before {
                        background-image: url('../img/info.svg');
                    }

                    &:hover {
                        background: #dd4f01;
                        color: white;

                        &::before {
                            background-image: url('../img/info-white.svg');
                        }
                    }
                }
            }

            #returns-link {
                .link-item {
                    &::before {
                        background-image: url('../img/retours.svg');
                    }

                    &:hover {
                        background: #dd4f01;
                        color: white;

                        &::before {
                            background-image: url('../img/retours-white.svg');
                        }
                    }
                }
            }

            #addresses-link {
                .link-item {
                    &::before {
                        background-image: url('../img/map.svg');
                    }

                    &:hover {
                        background: #dd4f01;
                        color: white;

                        &::before {
                            background-image: url('../img/map-white.svg');
                        }
                    }
                }
            }

            #history-link {
                .link-item {
                    &::before {
                        background-image: url('../img/orders.svg');
                    }

                    &:hover {
                        background: #dd4f01;
                        color: white;
    
                        &::before {
                            background-image: url('../img/orders-white.svg');
                        }
                    }
                }

                
            }

            #order-slips-link {
                .link-item {
                    &::before {
                        background-image: url('../img/avoirs.svg');
                    }

                    &:hover {
                        background: #dd4f01;
                        color: white;
    
                        &::before {
                            background-image: url('../img/avoirs-white.svg');
                        }
                    }
                }

                
            }

            #discounts-link {
                .link-item {
                    &::before {
                        background-image: url('../img/reductions.svg');
                    }

                    &:hover {
                        background: #dd4f01;
                        color: white;
    
                        &::before {
                            background-image: url('../img/reductions-white.svg');
                        }
                    }
                }

                
            }

            #psgdpr-link {
                .link-item {
                    &::before {
                        background-image: url('../img/rgpd.svg');
                    }

                    &:hover {
                        background: #dd4f01;
                        color: white;
    
                        &::before {
                            background-image: url('../img/rgpd-white.svg');
                        }
                    }
                }
            }

            #emailsalerts {
                .link-item {
                    &::before {
                        background-image: url('../img/alert.svg');
                    }

                    &:hover {
                        background: #dd4f01;
                        color: white;

                        &::before {
                            background-image: url('../img/alert-white.svg');
                        }
                    }
                }
            }
            
        }
    }

    .page-footer--my-account {
        display: none;
    }
}