.l-header{
  background-color: $header-bg;
  margin-bottom: 0;
  box-shadow: $header-box-shadow;
}

.header__up{
  align-content: center;
}

.header-top{
  flex-wrap: wrap;
  padding-top: $spacer*1.25;
  padding-bottom: $spacer*1.25;
}

.header__search{
  flex: 1;
  padding-right: $grid-gutter-width/2;
  padding-left: $grid-gutter-width/2;
}
.header__right,
.header-nav__right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__rightitem:not(:last-child){
  margin-right: $spacer/2;
}
.header__logo--checkout{
  max-width: 200px;
}
.header-nav--checkout{
  padding-top: $spacer;
  padding-bottom: $spacer;
}
@include media-breakpoint-mobile{
  .header__logo,
  .header__logo--checkout{
    max-width: 50%;
  }
  .header__search {
    width: 100%;
    padding: 0;
    flex: auto;
    order: 10;
    margin-top: 10px;
  }
  .header__logo--checkout{
    margin: 0 auto;
  }
}
@include media-breakpoint-desktop(){
  .header-top__col{
    width: 30%;
  }
}
@include media-breakpoint-up(xl) {
  .header-top__col{
    width: 25%;
  }
}


//
//.header-nav{
//  border-bottom: 1px solid $header-nav-border-color;
//  background-color: $header-nav-bg-color;
//  color:$header-nav-color;
//  padding-top: $header-nav-padding-y;
//  padding-bottom: $header-nav-padding-y;
//  & a:not(.dropdown-item), .btn{
//    color: $header-nav-color;
//  }
//}
////.header-top{
////  border-bottom: 1px solid $header-top-border-color;
////}
//@include media-breakpoint-mobile {
//  .header__container{
//    padding-left: 0;
//    padding-right: 0;
//  }
//  .logo{
//    width:100%;
//    max-width: 115px;
//  }
//}



.header-banner {
  background-color: #0002C0;
  text-align: center;
  // padding: 10px 0 1px 0;
  padding: 0px;

  p {
    color: white;
    font-size: 12px;
    font-weight: 700;

    a {
      color: white;
      text-decoration: underline;
      
      &:hover {
        color: $primary;
      }
    }
  }
}

#header {
  > .container {
    max-width: 100%;
    max-height: 75px;

    #header-full {
      > div {
        max-width: 100%;

        > .elementor-row {

          > div:nth-child(2) {
            padding: 10px 10px 0 10px;
          }

          div:nth-child(3){
            > div > div {
              display: flex;
              justify-content: flex-end;

              > div {
                width: auto;
              }
              // CONTACT
              > div:nth-child(1) {
                a {
                  background-color: transparent;

                  .elementor-button-content-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    .elementor-button-icon {
                      color: #001746;
                    }

                    .elementor-button-text {
                      width: 100%;
                      color: #001746;
                      margin-top: 10px;
                      font-size: 10px;
                    }
                  }
                }
              }

              // PANIER
              > div:nth-child(2) {
                a {
                  border: none;
                  display: flex;
                  justify-content: center;
                  flex-wrap: wrap;
                  padding: 11px;
                  margin: 0;

                  .elementor-button-icon {
                    color: #001746;
                  }

                  .elementor-button-text {
                    width: 100%;
                    color: #001746;
                    margin-top: 10px;
                    font-size: 10px;
                  }
                }
              }

              // MON COMPTE
              > div:nth-child(3) {
                .elementor-nav {
                  position: inherit;
                  top: inherit;

                    li:nth-child(1){
                      a {
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;

                        i {
                          color: #001746;
                        }

                        span {
                          width: 100%;
                          color: #001746;
                          font-size: 10px;
                        }
                      }
                    }
                }
              }
            }
          }
        }

        
        .elementor-nav {
          position: absolute;
          // top: -75px;

          li {
            a {
              padding: 13px 15px;
            }
          }

          .menu-item-lnk-accueil {

            a {
              background: url('/img/tlci-logo-1629363894.jpg') no-repeat;
              background-size: 56%;
              background-position: center;
              color: transparent;

              span {
                display: none;
              }

              &::before {
                display: none;
              }
            }

            > ul {
              display: none !important;
            }
          }

          .elementor-item:after {
            background-color: $primary;
          }

          > :nth-child(4){
            // margin-left: 60px;
          }
        }
      }
    }
  }
}

#header-full > div > div > div.elementor-element.elementor-element-2146274.elementor-column.elementor-col-50.elementor-top-column > div {
  padding: 0;
}

.elementor-widget-theme-site-logo {
  display: none;
  a {
    width: 10%;
    z-index: 999999;
    img {
    }
  }
}


.elementor-cart__main {
  .elementor-button--view-cart {
    span {
      margin-top: 0 !important;
      color: white !important;
      font-size: 15px !important;
    }
  }

  .elementor-button--checkout {
    span {
      margin-top: 0 !important;
      color: white !important;
      font-size: 15px !important;
    }
  }
}

.menu-item-type-account {

  
  .elementor-nav--dropdown {
    margin-top: -7px !important;

    li {
      a {

        i {
          margin-left: 10px !important;
          padding: 0;
        }
        span {
          padding: 0;
          text-align: center;
        }

        &:hover {

          i {
            color: white !important;
          }
          span {
            color: white !important;
          }
        }
      }
    }
  }

  > a {
    > span {
      padding: 0 !important;
      display: flex;
      justify-content: center;
    }
  }
}