html,
body,
* {
    font-family: 'Open sans', sans-serif;
}

main {
    overflow: hidden;
}

.row-product {
    display: none;
}

@import "abstracts/variables-bootstrap";
@import "bootstrap";
@import "abstracts/mixins";
@import "abstracts/variables";
@import "partials/fonts";

/** LAYOUT **/
@import "layout/header";
@import "layout/footer";
@import "layout/mainmenu";


/** PAGES **/
@import "pages/cart";
@import "pages/checkout";
@import "pages/category";
@import "pages/home";
@import "pages/order";
@import "pages/product";
@import "pages/account";
@import "pages/identity";
@import "pages/cms";
@import "pages/contact";

@import "partials/commons";
@import "partials/lazysizes";

/** COMPONENT **/
@import "components/alert";
@import "components/block-social";
@import "components/cart";
@import "components/categories";
@import "components/customer";
@import "components/footer";
@import "components/imageslider";
@import "components/offcanvas";
@import "components/products";
@import "components/searchbar";

@import "components/facet";
@import "components/search-widget";
@import "components/slick";
@import "components/slick-theme";
@import "components/productcomments";
@import "components/utilities";
@import "components/breadcrumb.scss";
@import "components/owl-carousel.scss";
@import "partials/bs_alpha";

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box; // 1. Add the correct box sizing in IE 10-
  padding: 0; // 2. Remove the padding in IE 10-
  border: 1px solid $h1-color;
}

/* MOBILE */
@media (max-width:767px){
    /* Header commun */
    header#header .elementor-container.elementor-column-gap-default .elementor-row {
        display: flex;
        flex-direction: row;
        height:60px;
    }
    header#header .elementor-container.elementor-column-gap-default .elementor-row .elementor-element-7d7ed0f:nth-child(1) { 
        order: 3; 
    }
       
    header#header .elementor-container.elementor-column-gap-default .elementor-row .elementor-element-2146274:nth-child(2) { 
        order: 1; 
    }
    header#header .elementor-container.elementor-column-gap-default .elementor-row .elementor-element-9f4e4f8:nth-child(3) { 
        order: 2; 
        margin-top:-60px;
    }
    .header-banner .everblock.everblock-1 {
        text-align:left;
        padding-left:10px;
    }
    .header-banner .everblock.everblock-1 p {
        margin-bottom:0.5rem;
    }
    .header-banner .everblock.everblock-1 a {
        float:right;
        margin-right:10px;
    }
    /**/
    /* Home page */
    body#index div#carousel .slick-slide img {
        height:200px;
        object-fit:cover;
    }
    body#index div#carousel .rc.rc--lazyload {
        height:200px;
    }
    body#index .elementor-1020101 .elementor-element.elementor-element-1eb3c9e {
        font-size: 15px;
    }
    body#index .elementor-1020101 .elementor-element.elementor-element-b9656cf.elementor-widget-heading .elementor-heading-title {
        font-size:23px;
    }
    body#index .elementor-1020101 .elementor-element.elementor-element-ae34d00 .elementor-heading-title, body#index .elementor-1020101 .elementor-element.elementor-element-b045d07 .elementor-heading-title, body#index .elementor-1020101 .elementor-element.elementor-element-1f91b7b .elementor-heading-title, body#index .elementor-1020101 .elementor-element.elementor-element-1545f1b.elementor-widget-heading .elementor-heading-title {
        font-size: 20px;
        padding:5px !important;
    }
    body#index .elementor-1020101 .elementor-element.elementor-element-cd378ce:not(.elementor-motion-effects-element-type-background) {
        background-color: #f1f1ff;
    }
    body#index .elementor-element.elementor-element-0502004.elementor-widget.elementor-widget-ps-widget-module section#tabbedcathome ul#filterCat {
        display:block;
    }
    body#index .elementor-element.elementor-element-0502004.elementor-widget.elementor-widget-ps-widget-module section#tabbedcathome ul#filterCat li {
        display:inline-block;
        width:46% !important;
        margin:2%;
        box-shadow: 0 0 2px rgba(0, 23, 70, 0.2);
    }
    body#index .elementor-element.elementor-element-0502004.elementor-widget.elementor-widget-ps-widget-module section#tabbedcathome ul#filterCat li a p {
        font-size: 14px !important;
        color: #001746;
        margin-top:-25px;
    }
    body#index section.elementor-element.elementor-element-015fdb5 .reassurance-home {
        display: block;
    }
    body#index section.elementor-element.elementor-element-015fdb5 .reassurance-home div {
        width:49%;
        display:inline-block;
        text-align:center;
        font-size: 12px;
    }
    body#index .elementor-1020101 .elementor-element.elementor-element-81fd31f:not(.elementor-motion-effects-element-type-background) {
        background-color: #f1f1ff;
    }
    body#index .elementor-1020101 .elementor-element.elementor-element-941d3c9 > .elementor-widget-container {
        padding: 0px;
    }
    body#index .elementor-1020101 .elementor-element.elementor-element-941d3c9 > .elementor-widget-container h2 {
        margin-top:0 !important;
    }
    body#index #magasin .blocs-magasin a img {
        height: 24px;
        margin-top:10px;
    }
    body#index #magasin .blocs-magasin a p {
        font-size: 13px;
        line-height: 14px;
        margin-top:10px;
    }
    body#index #magasin .blocs-magasin a {
        height: 105px;
        padding: 10px;
    }
    body#index .elementor-element.elementor-element-991e758.elementor-column.elementor-col-50.elementor-top-column {
        margin-bottom:-60px;
    }
    body#index .elementor-image-carousel.slick-arrows-outside.slick-initialized.slick-slider button.slick-prev.slick-arrow, body#index .elementor-image-carousel.slick-arrows-outside.slick-initialized.slick-slider button.slick-next.slick-arrow {
        display:none;
    }
    body#index .elementor-image-carousel-wrapper.elementor-slick-slider .slick-list.draggable{
        transform: scale(0.8);
    }
    body#index .elementor-image-carousel-wrapper.elementor-slick-slider .slick-list {
        overflow: visible;
        
    }
    body#index .elementor-element.elementor-element-f5d6a22.elementor-align-center.elementor-widget.elementor-widget-button, body#index .elementor-element.elementor-element-afad143.elementor-align-center.elementor-widget.elementor-widget-button, body#index .elementor-element.elementor-element-1264463.elementor-align-center.elementor-widget.elementor-widget-button {
        margin-top:-45px;
    }
    body#index .elementor-element.elementor-element-ae34d00.elementor-widget.elementor-widget-heading, body#index .elementor-element.elementor-element-1f91b7b.elementor-widget.elementor-widget-heading, body#index .elementor-element.elementor-element-1545f1b.elementor-widget.elementor-widget-heading {
        margin-bottom:-20px;
    }
    /**/
    /* Footer commun */
    .footer-container .container, .footer-container .l-wrapper--boxed {
        max-width: 90%;
        padding-left: 2%;
        padding-right: 2%;
    }
    footer#footer .footer-container .elementor-element-33e2b92 .elementor-widget-wrap .visible--desktop {
        display: block;
        padding-left:0;
    }
    footer#footer .footer-container .elementor-element-33e2b92 .elementor-widget-wrap > div:nth-child(1) {
        width:49%;
        margin-bottom:0;
    }
    footer#footer .footer-container .elementor-element-33e2b92 .elementor-widget-wrap > div:nth-child(2) {
        width:49%;
        border:none !important;
        margin-bottom:0;
    }
    footer#footer .footer-container .elementor-element-33e2b92 .elementor-widget-wrap > div:nth-child(3) {
        width:100%;
        border-right:none !important;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        padding-bottom:10px;
        padding-left:0 !important;
        margin-top:-12px;
        margin-bottom:0px;
    }
    footer#footer .footer-container .elementor-element-33e2b92 .elementor-widget-wrap > div:nth-child(4) {
        width:100%;
        padding-bottom:10px;
        padding-left:0 !important;
    }
    footer#footer .footer-container .elementor-element-33e2b92 .elementor-widget-wrap > div:nth-child(4) a.facebook {
        position:absolute;
        top:33px;
        right:0;
    }
    .footer-container .container p.text-center.mb-0.pb-3 {
        font-size:11px;
    }
    footer#footer .container .blockreassurance.col-sm-12 .row {
        display:block;
        padding:0 18px !important;
    }
    footer#footer .container .blockreassurance.col-sm-12 .row .col-md-3.offset-md-0.col-sm-6.offset-sm-0.col-xs-12 {
        width:49% !important;
        display:inline-block !important;
    }
    footer#footer .container .blockreassurance.col-sm-12 .row .col-md-3.offset-md-0.col-sm-6.offset-sm-0.col-xs-12:nth-child(2) svg {
        transform: scale(1.4);
    }
    footer#footer .container .blockreassurance.col-sm-12 .row .col-md-3.offset-md-0.col-sm-6.offset-sm-0.col-xs-12 .block-icon {
        width:100%;
        display:block;
        text-align:center;
    }
    footer#footer .container .blockreassurance.col-sm-12 .row .col-md-3.offset-md-0.col-sm-6.offset-sm-0.col-xs-12 .block-title {
        width:100%;
        max-width:100%;
        display:block;
        text-align:center;
    }
    footer#footer .blockreassurance>.products>div:after, footer#footer .blockreassurance>.row>div:after {
        border-right:none;
    }
    /**/
    /**/
    /* page category */
    body#category .block-category.card-block.visible--desktop {
        display:block;
    }
    body#category nav.visible--desktop {
        display:block;
        font-size:12px;
    }
    body#category div#_desktop_category_header h1.h1 {
        font-size:23px;
    }
    body#category .l-wrapper .d-flex #category-description p {
        width:100% !important;
    }
    body#category .card.card-product {
        display:block;
        border:none !important;
        box-shadow: 0 0 2px rgba(0, 23, 70, 0.2);
    }
    body#category .card-img-top.product__card-img {
        width:100% !important;
        max-width:100%;
    }
    body#category .card.card-product .card-body .row-product {
        display:block !important;
    }
    body#category .card.card-product .card-body .row-product .left {
        width:100% !important;
        color: #001746 !important;
    }
    body#category .card.card-product .card-body .row-product .right {
        width:100% !important;
        display:block !important;
        border-top:1px solid #f1f1ff;;
    }
    body#category .card.card-product .card-body .row-product .left .product_shortdesc {
        max-width:100% !important;
        color: #001746;
    }
    body#category .card.card-product .card-body .row-product h2.h3.product-title a {
        color: #001746 !important;
    }
    body#category .card.card-product .card-body .row-product .right .product-price-and-shipping.text-center {
        display:inline-block;
        width:49%;
        vertical-align:top;
    }
    body#category .card.card-product .card-body .row-product .right form.add-to-cart-or-refresh {
        display:inline-block;
        width:49%;
        vertical-align:top;
        margin-top:-12px;
    }
    body#category .card.card-product .card-body .row-product .right span.regular-price, body#category .card.card-product .card-body .row-product .right span.price.current-price-discount {
        display:block;
    }
    body#category .card.card-product .card-body .row-product .right span.price.current-price-discount {
        font-size:20px;
    }
    body#category .card.card-product .card-body .row-product .right span.regular-price {
        font-size:14px;
    }
    body#category .card.card-product .card-body .row-product .right .product-price-and-shipping.text-center {
        display:flex;
        float:left;
    }
    body#category .card.card-product .card-body .product-description.product__card-desc .stars-container {
        display:none;
    }
    /**/
    /* Page contact */
    #contact #content-wrapper {
        padding:0;
    }
    #contact #content-wrapper section#content {
        padding-left:0;
        padding-right:0;
    }
    #contact #content-wrapper section#content form.form_using_ajax.row {
        padding-left:0;
        padding-right:0;
        margin-right:0;
    }
    #contact #content-wrapper section#content .form-group.input_box .row {
        display:block !important;
        padding:0;
        margin:0;
    }
    #contact #content-wrapper section#content form.form_using_ajax .formbuilder_column.col-md-12.col-sm-12.col-xs-12 {
        padding:0;
        margin-left:15px;
    }
    #contact #content-wrapper section#content div#gformbuilderpro_4 .form-group.select_box .row {
        display:block !important;
        padding-left:15px;
        padding-right:15px;
    }
    /**/
    /* Page mon compte */
    body#my-account .row.account-links {
        display:block;
    }
    body#my-account .row.account-links a#identity-link {
        width:100%;
    }
    #my-account .l-wrapper .account-links #addresses-link .link-item, #my-account .l-wrapper .account-links #discounts-link .link-item, #my-account .l-wrapper .account-links #emailsalerts .link-item, #my-account .l-wrapper .account-links #history-link .link-item, #my-account .l-wrapper .account-links #identity-link .link-item, #my-account .l-wrapper .account-links #order-slips-link .link-item, #my-account .l-wrapper .account-links #psgdpr-link .link-item, #my-account .l-wrapper .account-links #returns-link .link-item, #my-account main>.notifications-container .account-links #addresses-link .link-item, #my-account main>.notifications-container .account-links #discounts-link .link-item, #my-account main>.notifications-container .account-links #emailsalerts .link-item, #my-account main>.notifications-container .account-links #history-link .link-item, #my-account main>.notifications-container .account-links #identity-link .link-item, #my-account main>.notifications-container .account-links #order-slips-link .link-item, #my-account main>.notifications-container .account-links #psgdpr-link .link-item, #my-account main>.notifications-container .account-links #returns-link .link-item {
        padding-top: 75px;
        padding-bottom:30px;
    }
    #my-account .l-wrapper .account-links #addresses-link .link-item:before, #my-account .l-wrapper .account-links #discounts-link .link-item:before, #my-account .l-wrapper .account-links #emailsalerts .link-item:before, #my-account .l-wrapper .account-links #history-link .link-item:before, #my-account .l-wrapper .account-links #identity-link .link-item:before, #my-account .l-wrapper .account-links #order-slips-link .link-item:before, #my-account .l-wrapper .account-links #psgdpr-link .link-item:before, #my-account .l-wrapper .account-links #returns-link .link-item:before, #my-account main>.notifications-container .account-links #addresses-link .link-item:before, #my-account main>.notifications-container .account-links #discounts-link .link-item:before, #my-account main>.notifications-container .account-links #emailsalerts .link-item:before, #my-account main>.notifications-container .account-links #history-link .link-item:before, #my-account main>.notifications-container .account-links #identity-link .link-item:before, #my-account main>.notifications-container .account-links #order-slips-link .link-item:before, #my-account main>.notifications-container .account-links #psgdpr-link .link-item:before, #my-account main>.notifications-container .account-links #returns-link .link-item:before {
        top: 30px;
    }
    /**/
    }
    /*fin section mobile*/