
#cart {
    #wrapper > div > div.row {
        padding: 10px 0px;
        width: 100%;
        margin: 30px auto;
    }

    .l-wrapper {
        background: #f1f1ff;

        #main > .row {
            padding: 10px 0;

        }

        #main {
            h1 {
                display: block;
                width: fit-content;
                margin: 30px auto;
                text-transform: inherit;
            }

            > p {
                display: block;
                width: fit-content;
                margin: 0 auto;
            }

            .card {
                background-color: transparent;
                border: none;

                .cart-item {
                    background: white;
                    padding-bottom: 0px;
                    // border: 1px solid #707070;

                    .row {
                        padding: 0px;

                        // COL de gacuhe Image et title 
                        > div:first-child {
                            padding: 10px;

                            .product-line__body {
                                display: flex;
                                flex-wrap: wrap;
                                flex-direction: column;
                                justify-content: center;
                                height: 100%;
                                min-height: 125px;

                                .product-line__title {
                                    color: #001746;
                                    font-size: 25px;
                                    font-weight: 600;
                                    line-height: 22px;
                                    text-transform: uppercase;
                                }
                            }

                            .product_quantities {
                                position: relative;
                                font-size: 12px !important;
                                // margin-left: 20px;
                                color: $h1-color !important;
                                text-align: initial;
                                margin: 0 20px;
                                display: block;
                                width: fit-content;
    
                                &::before {
                                    content: '';
                                    width: 11px;
                                    height: 11px;
                                    display: block;
                                    position: absolute;
                                    left: -18px;
                                    top: 4px;
                                    background: url('../img/oeil.png') no-repeat center;
                                    background-size: contain;
                                }
    
                            }
                        }

                        // COL de droite
                        > div:nth-child(2) {
                            // padding-left: 10px;

                            .qty-title {
                                font-size: 14px;
                                font-weight: 700;
                                color: #001746;
                                width: 100%;
                                margin: 0;
                                text-align: center;
                            }

                            .remove-icon {
                                width: 20px;
                                height: 20px;
                                display: block;
                                color: #001746;
                                font-weight: 600;
                                font-size: 20px;
                                transform: rotateZ(45deg);
                                position: absolute;
                                top: 6px;
                                right: 0;
                            }
                        }
                    }

                    .product-line-actions {
                        height: 100%;
                        position: relative;

                        .cart-line-product-actions {
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }


                    .bootstrap-touchspin {
                        box-shadow: none;
                        border: none;
                        max-width: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: center;

                        input {
                            width: 89px;
                            height: 45px;
                            border-radius: 23px;
                            background-color: #f1f1ff;
                        }

                        #quantity_wanted {
                            background-color: #F1F1FF;
                            border-radius: 22.5px;
                        }

                        button {
                            font-size: 25px !important;
                            padding: 0 10px !important;
                        }
                    }

                    .product-price {
                        height: 100%;
                        // border-left: 1px solid;
                        padding: 0 30px;
                        border-left: 1px solid #F1F1FF;
                        strong {
                            color: $primary !important;
                            font-size: 30px !important;
                            font-weight: 700 !important;
                        }
                    }
                }
            }

            .cart-summary {
                border: 1px solid #001746;

                .cart-summary-line {
                    .label {
                        font-weight: bold;
                    }
                }


                .cart-total {
                    background-color: transparent;
                    flex-wrap: wrap;

                    .label, .value {
                        width: 100%;
                        text-align: center;
                    }

                    .label {
                        color: #001746;
                        font-size: 14px;
                        font-weight: 700;
                    }

                    .value {
                        color: $primary;
                        font-size: 40px;
                        font-weight: 700;
                    }
                }

                .block-promo {
                    .promo-code__content {
                        background-color: transparent;
                    }
                }

                .card-footer {
                    background-color: transparent;
                    border: none;

                    .checkout {
                        .btn-primary {
                            width: 206px;
                            height: 45px;
                            border-radius: 23px;
                            background-color: #001746;
                            color: white;
                            margin: 0 auto;
                            text-transform: uppercase;
                            font-size: 12px;
                            font-weight: 700;
                            border: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }

                .separator {
                    display: none;
                }
            }
        }
    }
}