.everblock-3 {
    width: 50%;
    margin: 0 auto;

    h1 {
        color: #001746;
        font-size: 30px;
        font-weight: bold;
        line-height: 26px;
        text-transform: inherit;
    }
}


#index {

    h2 {
        background-color: white;
        width: fit-content;
        margin: 0 auto;
        padding: 10px;
        margin-top: 20px;
    }


    #tabbedcathome {
        #filterCat {
            li {
                background-color: white;
                width: 225px;
                height: 125px;


                a {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    padding: 0;

                    &:hover {
                        background: transparent;
                        color: inherit;
                        margin: 0;
                        padding: 0;
                    }

                    img {

                        height: 50px;
                    }
                    p {
                        width: 100%;
                        text-align: center;
                        text-transform: capitalize;
                        font-size: 16px;
                        line-height: 16px;
                    }
                }
            }
        }
    }

    #magasin {
        padding-top: 20px;

        .blocs-magasin {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            a {
                width: 28%;
                height: 125px;
                background-color: #001746;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 20px;

                img {
                    height: 40px;
                }

                p {
                    text-align: center;
                    width: 100%;
                    color: white;
                }
            }
        }
    }
}
.reassurance-home {
    display: flex;
    justify-content: space-around;
    padding-top: 30px;
    padding-bottom: 10px;

    > div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        img {
            
        }
        p {
            width: 100%;
            color: white;
            font-weight: bold;
            text-align: center;
            margin-top: 10px;
        }
    }
}

.slick-slider {
    > .slick-arrow::before {
        top: 40%;
        position: absolute;
    }
}

.card-product {
    min-height: 364px;

    .highlighted-informations {
        display: none;
    }
}


#index .row {
    padding: 0 !important; 
}